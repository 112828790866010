.aboutCard {
  overflow: visible;
  width: 300px;
  height: 550px;
  font-family: 'Raleway', sans-serif;
  padding: 1rem;
}

.content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  box-shadow: 0px 0px 5px 1px #000000ee;
  border-radius: 5px;
}

.front, .back {
  background-color: #adc0da;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
}

.back {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.back::before {
  position: absolute;
  content: ' ';
  display: block;
  width: 300px;
  height: 160%;
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  animation: rotation_481 5000ms infinite linear;
}

.back-content {
  position: absolute;
  bottom: 1px;
  width: 99%;
  height: 35%;
  background-color: #adc0da;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.aboutCard:hover .content {
  transform: rotateY(180deg);
}

.front .description {
  height: 100%;
}

.about {
  overflow: scroll;
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.front {
  transform: rotateY(180deg);
 
}

.front .front-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
}

.description {
  width: 99%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  backdrop-filter: blur(5px);
}

.title h1{
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}

.title p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.cardFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 5px 5px 5px;
}

.back .img {
  position: absolute;
  top: 1px;
  width: 99%;
  height: 65%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px 5px 0px 0px;
}

.back .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px 5px 0px 0px;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* .aboutCard_article {
  width: 300px;
  height: 550px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  background-color: #fff;
  font-family: 'Raleway', sans-serif;
  background-color: #adc0da;
}

.aboutCard_img {
  width: 100%;
  height: 85%;
  border-radius: 1rem;
  object-fit: cover;
}

.aboutCard_h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0.5rem 0;
  font-family: 'Raleway', sans-serif;
}

.aboutCard_p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem 0;
  font-family: 'Raleway', sans-serif;
} */