@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,0,0');



.navbar_header {
  position: relative;
  margin: 15px auto; 
  max-width: 1440px;
  width: calc(100% - 30px);
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap !important;
  padding: 0 1rem;
  top: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid #adc0da;
  font-family: 'Raleway', sans-serif;
  position: relative;
  border-radius: 5px;
  background: #adc0da;
  box-shadow:  5px 5px 5px #8a9aae,
             -5px -5px 5px #dab8a5;
}

.navbar_img {
  height: calc(100% + 30px);
}

.navbar_section {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
  flex-wrap: wrap;
  column-gap: 2rem;
  position: relative;
  font-family: 'Raleway', sans-serif;

}

.button_div {
  width: fit-content;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  column-gap: 2rem;
  font-family: 'Raleway', sans-serif;

}

.navbar_button{
  font-size: 20px;
  border: none;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;

}


.dropdown-content {
  display: none;
  flex-direction: column;
  
  position: absolute;
  left: 140px;
  top: 90px;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font-family: 'Raleway', sans-serif;
  row-gap: 0.2rem;
  padding: 0.5rem;
}

.dropdown-content button {
  font-size: 20px;
  color: black;
  border: none;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;

}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: 'Raleway', sans-serif;

}

.show_it {
  display: none;
}

.dropdown-content a:hover {background-color: #ddd; font-family: 'Raleway', sans-serif;}
.dropdown-content button:hover {background-color: #ddd; font-family: 'Raleway', sans-serif;}

.dropdown:hover .dropdown-content {display: flex; font-family: 'Raleway', sans-serif;}

/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */

@media only screen and (max-width: 800px) {
  .hide_it {
    display: none;
  }


  .show_it {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    row-gap: 0.2rem;
    padding: 0.5rem;
  }

  .show_it span {
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Raleway', sans-serif;
  }

  .menuIcon {
    padding: 5px;
  }

  .buttons {
    position: absolute;
    top:135px;
    right: 15px;
    height: fit-content;
    width: fit-content;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    column-gap: 2rem;
    font-family: 'Raleway', sans-serif;
    z-index: 5;
    background-color: #adc0da;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid #dab8a5;
    padding: 0.5rem;
    border-radius: 5px;
    border-top-right-radius: 0px;
  }

  .buttons button {
    font-size: 20px;
    color: black;
    border: none;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;

  }
}