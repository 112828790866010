* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;

  background: transparent;

  font-family: inherit;
  text-decoration: none;
}

ol,
ul,
li {
  display: block;

  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a img {
  border: none;
}

button,
a {
  color: inherit;

  cursor: pointer;
}

textarea {
  resize: none;
}

button,
input,
textarea,
select {
  border-radius: 0;

  appearance: none !important;
  -webkit-appearance: none !important;
}


body {
  margin: 0 auto;
  font-family: 'Raleway','Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dab8a5;
  width: 100%;
  color: #1d1d1f;
  font-size: 1.1rem;
  text-align: justify;
  font-weight: 400;
  line-height: 1.5;
  word-spacing: 0.2rem;

  /* background: linear-gradient(to right, #ffffff, #83ae31); */
}

h1 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0.5rem 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0.5rem 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}