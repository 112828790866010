.home {
  position: relative;
  display: flex;
  width: 100vw;
  margin: 0 auto;
  justify-content: space-evenly;
  align-self: center;
  align-items: stretch;
  font-family: 'Raleway', sans-serif;
  position: relative;
  min-height: calc(100vh - 200px);
  padding: 1rem;
  max-width: 1440px;
}

.home_img {
  width: 45%;
  margin: 15px;
  /* height: 80%; */
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-height: 600px;
  border-radius: 5px;
}

.home__img {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.home_container {
  width: 50%;
  height: 75%;
  padding: 15px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  border-radius: 5px;
  background: #dab8a5;
  box-shadow:  5px 5px 15px #ae9384,
             -5px -5px 15px #ffddc6;
}

.home_container h1 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0.5rem 0;
  font-family: 'Raleway', sans-serif;
}

.home_container p {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0.5rem 0;
  font-family: 'Raleway', sans-serif;
  line-height: 1.5; 
  word-spacing: 0.2rem;
  text-align: justify;
}

@media screen and (max-width: 1023px) {
  .home {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    width: 100vw;
  }

  .home_img {
    width: 98%;
    height: 380px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home_container {
    width: 98%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Raleway', sans-serif;
  }
}
  
