.services_section {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  font-family: 'Raleway', sans-serif;
  min-height: calc(100vh - 200px);
  padding: 1rem;
}

.services_header {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  font-family: 'Raleway', sans-serif;
}

.services_header h1 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0.5rem 0;
  font-family: 'Raleway', sans-serif;
}

.services_header p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem 0;
  display: flex;
  font-family: 'Raleway', sans-serif;
}

.services_article {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;
  font-family: 'Raleway', sans-serif;

}
