.about_ {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  min-height: calc(100vh - 200px);
  padding: 1rem;
  max-width: 1440px;
}

.about_company {
  max-width: 1220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.about_p {
  background-color: #adc0da;
  padding: 20px;
  border-radius: 1rem;
}

.about_section {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  flex-wrap: wrap;
}

.about_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.about_header h1 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0.5rem 0;
}

.about_header p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem 0;
  display: flex;
}

.about_article {
  min-width: 300px;
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1220px;
  
}

.about_company .about_article {
  border-radius: 5px;
  background: #adc0da;
  box-shadow:  5px 5px 5px #8a9aae,
             -5px -5px 5px #dab8a5;
}

.about_section .about_article {
  width: 100%;
}