.contact {
  max-width: 1440px;
  min-width: 375px;
  margin:  0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  min-height: calc(100vh - 200px);
  padding: 1rem;
  column-gap: 1rem;
}

.contact__left {
  width: 50%;
  min-width: 375px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 600px;
  border-radius: 5px;
}

.contact .contact_Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.contact .contact__right {
  width: 48%;
  min-width: 375px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  padding-bottom: 1rem;
  border-radius: 5px;
  background: #dab8a5;
  box-shadow:  5px 5px 15px #ae9384,
  -5px -5px 15px #ffddc6;
}

.contact .contact__right__details {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
}

.contact__right__details__item__phone {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.contact__right__details__item__title{
  width: 25%;
}

.contact__right__details__item__phones{
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

.contact__right__details__item {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.contact__right__details__item__text{
  width: 75%;
}

.contact__right__offer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

.contact__right__offer__text{
  font-size: 0.85rem;
}

.contact__right__offer__mailto__button {
  border: 1px solid #adc0da;  
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #adc0da;
  box-shadow:  5px 5px 5px #8a9aae,
  -5px -5px 5px #dab8a5;
  
}

@media screen and (max-width: 1023px) {
  .contact {
    width: 100vw;
    min-width: 375px;
    height: fit-content;
    min-height: calc(100vh - 200px);
    margin:  0 auto;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    column-gap: 1rem;
  }
  
  .contact .contact__right {
    width: 98%;
    min-width: 375px;
    height: 50%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
    background: #dab8a5;
    box-shadow:  5px 5px 15px #ae9384,
    -5px -5px 15px #ffddc6;
  }
  
  .contact .contact__right__details {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .contact__right__details__item__phone {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
  
  .contact__right__details__item__title{
    width: 50%;
    display: flex;
    justify-content: space-around;
  }
  
  .contact__right__details__item__phones{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
  }

  .contact__right__details__item {
    padding: 0;
  }
  
  .contact__right__offer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
  }
  
  .contact__left {
    width: 98%;
    height: 380px;
    margin: 0 auto;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact .contact_Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .contact__right__details__item__text{
    min-width: fit-content;
  }
}

@media screen and (max-width: 749px) {
  .contact {
    width: 100vw;
    min-width: 375px;
    height: fit-content;
    min-height: calc(100vh - 200px);
    margin:  0 auto;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    column-gap: 1rem;
  }
  
  .contact .contact__right {
    width: 98%;
    min-width: 375px;
    height: 50%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
    background: #dab8a5;
    box-shadow:  5px 5px 15px #ae9384,
    -5px -5px 15px #ffddc6;
  }
  
  .contact .contact__right__details {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .contact__right__details__item__phone {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
  
  .contact__right__details__item__title{
    width: 50%;
    display: flex;
    justify-content: space-around;
  }
  
  .contact__right__details__item__phones{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
  }

  .contact__right__details__item {
    padding: 0;
  }
  
  .contact__right__offer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
  }
  
  .contact__left {
    width: 98%;
    height: 380px;
    margin: 0 auto;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
  }
  
  .contact .contact_Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .contact__right__details__item__text{
    min-width: fit-content;
  }
}

@media screen and (max-width: 409px) {
  .contact {
    width: calc(100vw - 30px);
    height: fit-content;
    min-height: calc(100vh - 200px);
    margin:  0 auto;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    column-gap: 1rem;
    min-width: none;
    padding: 0;
  }
  
  .contact .contact__right {
    width: 98%;
    min-width: 375px;
    height: 50%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
    background: #dab8a5;
    box-shadow:  5px 5px 15px #ae9384,
    -5px -5px 15px #ffddc6;
  }
  
  .contact .contact__right__details {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .contact__right__details__item__phone {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
  
  .contact__right__details__item__title{
    width: 50%;
    display: flex;
    justify-content: space-around;
  }
  
  .contact__right__details__item__phones{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
  }

  .contact__right__details__item {
    padding: 0;
    flex-direction: column;
  }
  
  .contact__right__offer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
  }

  .contact__right__offer p {
    line-height: 25px;
  }
  
  .contact__left {
    width: 98%;
    height: 380px;
    margin: 0 auto;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
  }
  
  .contact .contact_Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .contact__right__details__item__text{
    min-width: fit-content;
  }
}