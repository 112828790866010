.impresszum {
  position: relative;
  min-height: calc(100vh - 200px);
  max-width: 1220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2rem;
}