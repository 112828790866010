.footer__ {
  height: 50px;
  width: 100%;
  background-color: #adc0da;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: 0 20px;
  column-gap: 20px;
}

.footer__button {
  font-size: 1rem;
}