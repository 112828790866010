Card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  background-color: #adc0da;
}

/* .serviceCard_h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0.5rem 0;
  font-family: 'Raleway', sans-serif;
} */

.price-card {
  backgroundColor: "#adc0da"
}