.serviceCard_article {
  width: 300px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  font-family: 'Raleway', sans-serif;
  background-color: #adc0da;
}

/* .serviceCard_h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0.5rem 0;
  font-family: 'Raleway', sans-serif;
} */

.serviceCard_p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem 0;
  display: flex;
  font-family: 'Raleway', sans-serif;
  text-align: justify;
  padding: 0.5rem;
}

@media screen and (max-width: 702px) {
  .serviceCard_article {
  
    height: fit-content;
  
  }
}

.card {
  background: #adc0da;
  padding: 1rem;
  width: 300px;
}

.card p {
  padding-top: 1rem;
  line-height: 150%;
}
  