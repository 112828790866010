.pdf {
  position: relative;
  min-height: calc(100vh - 200px);
  max-width: 1220px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
}

.pdf iframe {
  width: 100%;
  height: auto;
  border: none;
  border-radius: 5px 5px 0px 0px;
}